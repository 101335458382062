/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Hide the scrollbar on ion-content but still scroll
/* ion-content {
  // overwrite inline styles
  --offset-bottom: auto !important; 
  --overflow: hidden;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
} */

.md ion-header {
  backdrop-filter: blur(10px);
}

// node-vibrant colour swatch variables
$darkmuted: var(--darkmuted);
$darkvibrant: var(--darkvibrant);
$lightmuted: var(--lightmuted);
$lightvibrant: var(--lightvibrant);

ion-button[shape=circle] {
  --border-radius: 50%;
  margin-left: 10px;
  width: 56px;
  height: 56px;
}

ion-button[shape=circle][size=small] {
  --border-radius: 50%;
  margin-left: 10px;
  width: 46px;
  height: 46px;
}

ion-button[shape=circle][size=default] {
  --border-radius: 50%;
  margin-left: 10px;
  width: 56px;
  height: 56px;
}

ion-button[shape=circle][size=large] {
  --border-radius: 50%;
  margin-left: 10px;
  width: 66px;
  height: 66px;
}


.head-img-sidemenu {
  width:20%;
  padding-left: 15px;
  display: flex;
  margin: 0 auto;
}

.head-img-titlebar {
  max-width:50px;
  padding-left: 15px;
  display: flex;
  margin: 0 auto;
}


// splitpane border
.split-pane-visible > ion-menu {
  border: none;
}

// maintain high on empty <p> tag
.p-empty {
  min-height: 16.5px;
}

// action sheet title and cancel colours
.action-sheet-destructive.sc-ion-action-sheet-ios { color: var(--ion-color-danger); }
.action-sheet-destructive.sc-ion-action-sheet-md { color: var(--ion-color-danger); }
.action-sheet-title.sc-ion-action-sheet-ios { color: var(--ion-color-primary); }
.action-sheet-title.sc-ion-action-sheet-md { color: var(--ion-color-primary); }

// ion-refresher visibility bug
ion-refresher {
    z-index: 999;
}

// make sure ion-item is always full width
ion-item {
    width: 100%;
}

// selectively override the ion-item background
// used for mini player
.dark-theme {
    //backdrop-filter: blur(10px);
    background-color: #000000 !important;
}

.light-theme {
    //backdrop-filter: blur(10px);
    background-color: #ffffff !important;
}

// increase modal height on larger browser windows
@media only screen and (min-width : 768px) {
    .modal-web {
        --height: 90%;
    }
    .modal-mobile {
        --height: 90%;
    }

    .img-contain-player {
        width: 100% !important;
        height: 100% !important;
        max-width: 60%;
        object-fit: cover !important;
    }
}

// drop opacity to half
.half-opacity {
    opacity: 0.5;
}

// rows should grow to fill available space
.flex-row {
    flex-grow: 1;
}

// drop shadows from SVGs
// .light-shadow{
//     filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
// }
  
// .dark-shadow{
//     filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 1));
// }


// drop shadow from main image on detail views & also thumbnails (square images)
.main-image-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

// fade in image gradually
.image-fadein {
    opacity: 1;
    z-index: 1;
    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}

// flash download icon when active
.animate-flicker {
    -webkit-animation: flickerAnimation 3s infinite;
    -moz-animation: flickerAnimation 3s infinite;
    -o-animation: flickerAnimation 3s infinite;
    animation: flickerAnimation 3s infinite;
 }

 // drop shadow from main artist image & also from avatars (round images)
.shadow-round {
//    -webkit-box-shadow: 0px 3px 10px 6px rgba(0,0,0,0.2);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
//    -moz-box-shadow:    0px 3px 10px 6px rgba(0,0,0,0.2) ;  /* Firefox 3.5 - 3.6 */
//    box-shadow:         0px 3px 10px 6px rgba(0,0,0,0.2) ;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 50%;
    width:100%;
    height: 100%;
//    background-color: rgba(0,0,0,0.5)
}

// rounded input fields for login
.rounded-input {
    --border-color: var(--ion-color-medium-shade);
    --border-radius: 25px;
    --border-width: 0px;
    --box-shadow: 2px gray;
    --highlight-height: 0;
    --background: #f8f9fa;
    margin-top: 10px;
    margin-bottom: 10px;
}

// For horizontal scrolling lists on home
.scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    height: 250px;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  
    .scrolling-item {
      flex: 0 0 auto;
    }
  }

// For horizontal scrolling lists on home (suggested Items)
.scrolling-wrapper-flexbox2 {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    //height: 250px;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  
    .scrolling-item {
      flex: 0 0 auto;
    }
  }

// format play and shuffle buttons to same width
ion-button.fixed-width {
    min-width: 122px;
}

// fit image to parent element
.img-contain {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}

// fit image to parent element
.img-contain-carousel {
  min-height: 150px;
  background-color: #000000;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

// fit image to parent element adjusted for player view
// to limit max size
.img-contain-player {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    max-width: 400px;
}

.img-contain-detail {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  max-width: 300px;
  max-height: 300px;
}

.img-contain-big-mini-player {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  max-width: 180px;
  max-height: 180px;
}

// test for blurred background image from:
// https://github.com/m0ngr31/jellyamp
.bg-album {
    min-height: calc(100vh + 30px);
    min-width: calc(100vw + 30px);
    overflow: hidden;
    position: fixed;
    top: 0;
}
img.bg-album {
    filter:blur(15px) opacity(80%);
    margin: -15px;
}

// style ion-range knob on player view
ion-range {
    --knob-background: var(--ion-color-primary);	
    --knob-size: 15px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
}

// Slide to the left
.slide-out-left {
	-webkit-animation: slide-out-left 0.5s linear both;
	        animation: slide-out-left 0.5s linear both;
          will-change: transform;
}

// Slide to the left
.slide-in-left {
	-webkit-animation: slide-in-left 0.5s linear both;
	        animation: slide-in-left 0.5s linear both;
          will-change: transform;
}

// Slide to the right
.slide-out-right {
	-webkit-animation: slide-out-right 0.5s linear both;
	        animation: slide-out-right 0.5s linear both;
          will-change: transform;
}

// Slide to the right
.slide-in-right {
	-webkit-animation: slide-in-right 0.5s linear both;
	        animation: slide-in-right 0.5s linear both;
          will-change: transform;
}

.flip-vertical-right {
	-webkit-animation: flip-vertical-right 0.6s linear both;
	        animation: flip-vertical-right 0.6s linear both;
          will-change: transform;
}

.fade-in {
	-webkit-animation: fade-in 1.2s linear both;
	        animation: fade-in 1.2s linear both;
          will-change: transform;
}

/* ----------------------------------------------
* Generated by Animista on 2020-7-16 9:54:29
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
    }
  }
  @keyframes slide-out-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
    }
  }
  

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  

/**
 * ----------------------------------------
 * animation slide-out-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-right {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
    }
  }
  @keyframes slide-out-right {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
    }
  }
  

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
/**
 * ----------------------------------------
 * animation flip-vertical-right
 * ----------------------------------------
 */
 @-webkit-keyframes flip-vertical-right {
    0% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
    }
    100% {
      -webkit-transform: rotateY(360deg);
              transform: rotateY(360deg);
    }
  }
@keyframes flip-vertical-right {
    0% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
    }
    100% {
      -webkit-transform: rotateY(360deg);
              transform: rotateY(360deg);
    }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

// fade in / fade out / flicker animation keyframes
@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0.5; }
    100% { opacity:1; }
  }
  @-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.5; }
    100% { opacity:1; }
  }
  @-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.5; }
    100% { opacity:1; }
  }
  @-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.5; }
    100% { opacity:1; }
  }

// animation keyframes
@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

// animation keyframes
@keyframes fadeoutpartial {
    from { opacity: 1; }
    to   { opacity: 0.9; }
}

/* Firefox < 16 */
@-moz-keyframes fadeoutpartial {
    from { opacity: 1; }
    to   { opacity: 0.9; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeoutpartial {
    from { opacity: 1; }
    to   { opacity: 0.9; }
}

/* Internet Explorer */
@-ms-keyframes fadeoutpartial {
    from { opacity: 1; }
    to   { opacity: 0.9; }
}

/* Opera < 12.1 */
@-o-keyframes fadeoutpartial {
    from { opacity: 1; }
    to   { opacity: 0.9; }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}